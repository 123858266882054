// import "nouislider/dist/nouislider.css";
// import "toastify-js/src/toastify.css";
// import "tippy.js/dist/tippy.css";
import "../css/animate.css";
import "../css/app.css";

// import setScrollBarWidth from "set-scrollbar-width";
import isMobilePhone from "validator/lib/isMobilePhone";
import isEmail from "validator/lib/isEmail";
import MicroModal from "micromodal";
import Client from "@yllet/client";
import Toastify from "toastify-js";
import tippy from "tippy.js";
import "@iconfu/svg-inject";
import IMask from "imask";
// import wNumb from "wnumb";

import {
  clearQueryParams,
  sendFormDataOnTermoApi,
  refreshFragments,
  onShowMicroModal,
  onCloseMicroModal,
  setElementsHeightAsCssVariable,
} from "./modules/functions";

import VideoAutoplay from "./modules/VideoAutoplay";
import ToggleContent from "./modules/toggle-content";
import ScrollToAnchor from "./modules/smooth-scroll";
import Accordion from "./modules/accordion";
import BurgerPlugin from "./modules/burger";
import FloatSidebar from "./modules/FloatSidebar";
// import SnapSlider from "./modules/slider";
import "./modules/comment-reply";

const { __, _n } = wp.i18n;

document.Toastify = Toastify;
document.tippy = tippy;
document.cartUpdatedEvent = new CustomEvent("cartUpdated");

String.prototype.format = function () {
  return [...arguments].reduce((p, c) => p.replace(/%s/, c), this);
};

const toggleLoader = (element) => {
  element.classList.toggle("loading");
  const parentLoading = element.closest("[data-parent-loading]");
  parentLoading?.classList.toggle("block-loading");
};

setTimeout(() => {
  SVGInject(document.querySelectorAll("img.style-svg"));
}, 1500);

MicroModal.init();

let isThrottled = false;

const getScrollbarWidth = (cssVariableName = "--twcb-scrollbar-width") => {
  if (isThrottled) return;

  isThrottled = true;

  requestAnimationFrame(() => {
    const prevWidth = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue(cssVariableName);
    const newWidth = `${window.innerWidth - document.body.clientWidth}px`;

    if (newWidth !== prevWidth) {
      document.documentElement.style.setProperty(cssVariableName, newWidth);
    }

    isThrottled = false;
  });
};

window.addEventListener("resize", () => {
  getScrollbarWidth();
  setElementsHeightAsCssVariable([
    {
      element: document.getElementById("header"),
      cssVariableName: "--header-height",
    },
  ]);
});

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll('.VideoAutoplay, .wp-block-video video')?.forEach(el => {
		new VideoAutoplay(el);
	});

  // Плаваючий сайдбар
  new FloatSidebar(
    document.getElementById("sidebar"),
    document.getElementById("content")
  );

  setTimeout(() => {
    getScrollbarWidth();
    setElementsHeightAsCssVariable([
      {
        element: document.getElementById("header"),
        cssVariableName: "--header-height",
      },
    ]);
  }, 1000);

  clearQueryParams(["canceled-action", "provider"]);

  if (theme.wc_notices) {
    for (const key in theme.wc_notices) {
      if (Object.hasOwnProperty.call(theme.wc_notices, key)) {
        const notices = theme.wc_notices[key];
        notices.forEach((el) => {
          Toastify({
            text: el.notice,
            className: key,
            duration: 3000,
            escapeMarkup: false,
          }).showToast();
        });
      }
    }
  }

  new BurgerPlugin(
    document.querySelectorAll(".burger"),
    document.getElementById("mobile-menu"),
    "menu"
  );

  new BurgerPlugin(
    document.querySelectorAll(".burger-filter"),
    document.getElementById("sidebar"),
    "filter"
  );

  if (document.getElementById("catalogBtn")) {
    new ToggleContent(
      document.getElementById("catalogBtn"),
      document.getElementById("catalogContent")
    );
  }

  openModalWithData();

  tippy(document.querySelectorAll(".tippy"), { allowHTML: true });

  new ScrollToAnchor();

  if (document.querySelectorAll("[data-accordion]")) {
    document
      .querySelectorAll("[data-accordion]")
      .forEach((el) => new Accordion(el));
  }

  document.querySelectorAll('input[type="email"]')?.forEach((el) => {
    el.addEventListener("input", () => {
      el.setCustomValidity("");
      el.checkValidity();
    });
  });

  document
    .querySelectorAll('input[name="tel"], input[name="billing_phone"]')
    ?.forEach((el) => {
      IMask(el, {
        mask: [
          {
            mask: "+{38\\0} 00 00 00 000",
          },
          {
            mask: "000 00 00 000",
            startsWith: "0",
          },
          {
            mask: "0000000000000",
          },
        ],
      });
      el.addEventListener("input", () => {
        el.setCustomValidity("");
        el.checkValidity();
      });
    });

  document.querySelectorAll('input[name="name"]')?.forEach((el) => {
    IMask(el, {
      mask: [
        {
          mask: /^[а-яА-ЯёЁіІїЇa-zA-Z\s]+$/,
        },
      ],
    });
  });

  document.querySelectorAll("[btn-open-modal-form-callback]")?.forEach((el) => {
    el.addEventListener("click", function () {
      // modalFormCallback.open();
      MicroModal.show("modal-form-callback", {
        disableScroll: true,
        onShow: onShowMicroModal,
        onClose: onCloseMicroModal,
        awaitOpenAnimation: true,
        // awaitCloseAnimation: true,
      });
      const parent = document.querySelector(el.dataset.btnParent);
    });
  });

  document.querySelectorAll("[data-form-callback]").forEach((el) => {
    el.addEventListener("submit", async (e) => {
      e.preventDefault();

      const loadingElement = el.querySelector('[type="submit"]');
      const formData = new FormData(el);

      if (formData.has("email")) {
        const input = el.querySelector('input[type="email"]');
        if (!isEmail(formData.get("email"))) {
          input.setCustomValidity(
            __("This is not Email", "frontenda-store-front")
          );
          input.reportValidity();
          return false;
        }
      }
      if (formData.has("tel")) {
        const input = el.querySelector('input[name="tel"]');
        if (!isMobilePhone(formData.get("tel").replaceAll(" ", ""))) {
          input.setCustomValidity(
            __("This is not Phone", "frontenda-store-front")
          );
          input.reportValidity();
          return false;
        }
      }

      formData.append("_wpnonce", theme.api.nonce);

      const data = new URLSearchParams(formData);
      const headers = new Headers();
      headers.append("Content-Type", "application/x-www-form-urlencoded");

      toggleLoader(loadingElement);

      let response = await fetch(theme.api.ajax_url + "?action=contactForm", {
        method: "POST",
        headers: headers,
        body: data,
      });

      let result = await response.json();
      let closeModal = false;
      document.querySelectorAll(".modal").forEach((el) => {
        if (el.classList.contains("is-open")) {
          closeModal = true;
        }
      });
      if (closeModal) {
        MicroModal.close();
      }

      if (result.success) {
        localStorage.setItem("formData", data);
        localStorage.setItem("showCloseContent", true);
        document.querySelectorAll(".hidden-content")?.forEach((el) => {
          el.remove();
        });
        MicroModal.show("modal-form-thankyou", {
          disableScroll: true,
          onShow: onShowMicroModal,
          onClose: onCloseMicroModal,
          awaitOpenAnimation: true,
          // awaitCloseAnimation: true,
        });
      } else {
        MicroModal.show("modal-form-error", {
          disableScroll: true,
          onShow: onShowMicroModal,
          onClose: onCloseMicroModal,
          awaitOpenAnimation: true,
          // awaitCloseAnimation: true,
        });
      }
      toggleLoader(loadingElement);

      el.reset();
    });
  });

  const header = document.getElementById("header");
  if (header) {
    scrollHeaderPlugin();
  }
  const commentForm = document.getElementById("commentForm");
  commentForm?.addEventListener("submit", (e) => {
    const submitBtn = commentForm.querySelector("[type=submit]");
    e.preventDefault();

    const type = commentForm.dataset.currentType;
    let formData = serializeForm(commentForm);

    const client = new Client({
      endpoint: theme.api.endpoint,
      nonce: theme.api.nonce,
    });

    submitBtn.classList.add("loading");

    switch (type) {
      case "comment":
        client
          .comments()
          .create(formData)
          .then((response) => {
            submitBtn.classList.remove("loading");
            MicroModal.show("modal-form-review-thankyou", {
              disableScroll: true,
              onShow: onShowMicroModal,
              onClose: onCloseMicroModal,
              awaitOpenAnimation: true,
              // awaitCloseAnimation: true,
            });
          })
          .catch(({ response }) => {
            console.log("Error code: " + response.code);

            submitBtn.classList.remove("loading");

            MicroModal.show("modal-form-review-error", {
              disableScroll: true,
              onShow: onShowMicroModal,
              onClose: onCloseMicroModal,
              awaitOpenAnimation: true,
              // awaitCloseAnimation: true,
            });
          });
        break;

      case "review":
        /*
      $review_data = [
        'product_id' => $data['post'],
        'review' => $data['content'],
        'reviewer' => $reviewer,
        'reviewer_email' => $reviewer_email,
        'rating' => $data['rating'],
        'status' => 'hold'
      ];
      */
        client
          .namespace("termo/v1")
          .resource("products/reviews")
          .create({
            productId: formData.post,
            review: formData.content,
            reviewer: formData.reviewer_name ?? formData.author_name,
            reviewerEmail: formData.reviewer_email ?? formData.author_email,
            rating: formData.rating,
            status: "hold",
            // _wpnonce: theme.api.nonce,
          })
          .then((response) => {
            submitBtn.classList.remove("loading");
            MicroModal.show("modal-form-review-thankyou", {
              disableScroll: true,
              onShow: onShowMicroModal,
              onClose: onCloseMicroModal,
              awaitOpenAnimation: true,
              // awaitCloseAnimation: true,
            });
          })
          .catch(({ response }) => {
            console.error("Error code: " + response.code);

            submitBtn.classList.remove("loading");

            MicroModal.show("modal-form-review-error", {
              disableScroll: true,
              onShow: onShowMicroModal,
              onClose: onCloseMicroModal,
              awaitOpenAnimation: true,
              // awaitCloseAnimation: true,
            });
          });
        break;

      default:
        break;
    }
  });

  document.querySelectorAll(".js-change-currency").forEach((el) =>
    el.addEventListener("click", function () {
      setCookie("currency", el.dataset.code, 1);
      location.reload();
    })
  );
});

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll("img")?.forEach((el) => {
		el.addEventListener("error", () => el.src = theme.directory + '/dist/img/placeholder.jpg');
	});
})

// Set a Cookie
function setCookie(cName, cValue, expDays) {
  let date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}

// Apply setCookie

function openModalWithData() {
  document.querySelectorAll("[data-btn-open-modal-form]")?.forEach((el) => {
    el.addEventListener("click", function (e) {
      e.stopPropagation();
      const attrs = isJSONString(el.dataset.btnOpenModalForm);
      let modalId;
      if (attrs) {
        const { id, texts, values } = attrs;
        modalId = `modal-form-${id}`;
        if (texts) {
          for (const key in texts) {
            if (Object.hasOwnProperty.call(texts, key)) {
              const element = texts[key];
              document.getElementById(`${modalId}-${key}`).innerHTML =
                wrapTextInSpan(element);
            }
          }
        }
        if (values) {
          for (const key in values) {
            if (Object.hasOwnProperty.call(values, key)) {
              const element = values[key];
              document.getElementById(`${modalId}-${key}`).value = element;
            }
          }
        }
      } else {
        modalId = el.dataset.btnOpenModalForm;
      }

      // Show Modal
      MicroModal.show(modalId, {
        disableScroll: true,
        onShow: onShowMicroModal,
        onClose: onCloseMicroModal,
        awaitOpenAnimation: true,
      });
    });
  });
}
function wrapTextInSpan(text) {
  return text.replace(/\{([^}]+)\}/g, "<span>$1</span>");
}
function isJSONString(str) {
  try {
    return JSON.parse(str);
  } catch {
    return false;
  }
}
// НЕ УЗГОДЖЕНО

async function actionToggle(btn, endpoint, action = "toggle") {
  const productNode = btn.closest("[data-id]");
  const qty = btn.dataset.qty ?? 1;
  if (action == "toggle") {
    action = productNode.classList.contains("in-" + endpoint)
      ? "remove"
      : "add";
  }

  const formData = new FormData();
  formData.append("product_id", getProductIdFromElement(btn));
  formData.append("action", action);
  formData.append("qty", qty);

  if (endpoint == "cart" && action == "remove") {
    formData.append("cart_item_key", productNode.dataset.cartItemKey);
  }

  await sendFormDataOnTermoApi(endpoint, {
    formData: formData,
    loadingElement: btn,
    success: (data) => {
      productNode.classList[action]("in-" + endpoint);

      if (data.cart_item_key) {
        productNode.dataset.cartItemKey = data.cart_item_key;
      }

      // if (data.count == 0) {
      // 	window.location.reload();
      // }

      const counters = document.querySelectorAll(`[data-type="${endpoint}"]`);

      counters.forEach((el) => {
        el.dataset.count = data.count;
        el.parentNode.parentNode.classList.add("tada");
        setTimeout(() => {
          el.parentNode.parentNode.classList.remove("tada");
        }, 1000);
      });
      
      try {
        showToastifyMesage(data.notices.success[0].notice);
      } catch (error) {
        console.error(error);        
      }

      if (endpoint == "cart") {
        triggerCartUpdatedEvent(data);
      }
    },
  });
}

function showToastifyMesage(mesagge, type = "info") {
  document
    .Toastify({
      text: mesagge,
      className: type,
      duration: 3000,
      escapeMarkup: false,
    })
    .showToast();
}

window.wishlist = async function (btn) {
  const wishlist = await actionToggle(btn, "wishlist");
  // Додатковий код для wishlist
};

window.compare = async function (btn) {
  const comparelist = await actionToggle(btn, "compare");
  // Додатковий код для compare
};

window.addToCart = async function (btn, action = "toggle") {
  const cart = await actionToggle(btn, "cart", action);
  // Додатковий код для compare
};

window.removeFromCart = async function (btn) {
  const endpoint = "cart";
  const action = "remove";
  const productNode = btn.closest("[data-cart-item-key]");
  const cartItemKey = productNode.dataset.cartItemKey;
  const formData = new FormData();

  formData.append("product_id", getProductIdFromElement(btn));
  formData.append("action", action);
  formData.append("cart_item_key", cartItemKey);

  await sendFormDataOnTermoApi(endpoint, {
    formData: formData,
    loadingElement: btn,
    success: (data) => {
      const counters = document.querySelectorAll(`[data-type="${endpoint}"]`);

      if (data.count == 0) {
        window.location.reload();
      }

      counters.forEach((el) => {
        el.dataset.count = data.count;
      });

      try {
        showToastifyMesage(data.notices.success[0].notice);
      } catch (error) {
        console.error(error);        
      }

      triggerCartUpdatedEvent(data);
    },
  });
};

window.updateItemInCart = async function (count, cartItemKey) {
  if (window.controllerUpdateItemInCart) {
    window.controllerUpdateItemInCart.abort();
  }

  const endpoint = "cart";
  const action = "update";

  window.controllerUpdateItemInCart = new AbortController();
  window.signalUpdateItemInCart = window.controllerUpdateItemInCart.signal;

  const formData = new FormData();

  formData.append("qty", count);
  formData.append("action", action);
  formData.append("lang", theme.lang);
  formData.append("cart_item_key", cartItemKey);

  await sendFormDataOnTermoApi(endpoint, {
    formData: formData,
    signal: window.signalUpdateItemInCart,
    success: (data) => {
      const counters = document.querySelectorAll(`[data-type="${endpoint}"]`);

      if (data.count == 0) {
        window.location.reload();
      }

      counters.forEach((el) => {
        el.dataset.count = data.count;
      });
      
      try {
        showToastifyMesage(data.notices.success[0].notice);
      } catch (error) {
        console.error(error);        
      }

      triggerCartUpdatedEvent(data);
    },
    loadingElement: document.querySelector(`[data-cart-item-key="${cartItemKey}"]`),
  });
};

window.applyCoupon = async function (element) {
  const endpoint = "cart/coupon";
  const action = "apply";
  const formData = new FormData();

  formData.append("coupon", document.getElementById("coupon_code").value);
  formData.append("action", action);

  await sendFormDataOnTermoApi(endpoint, {
    formData: formData,
    loadingElement: element,
    success: (data) => {
      triggerCartUpdatedEvent(data);
    },
  });
};

window.removeCoupon = async function (element) {
  const endpoint = "cart/coupon";
  const action = "remove";
  const formData = new FormData();

  formData.append("coupon", document.getElementById("coupon_code").value);
  formData.append("action", action);

  await sendFormDataOnTermoApi(endpoint, {
    formData: formData,
    loadingElement: element,
    success: (data) => {
      triggerCartUpdatedEvent(data);
    },
  });
};

window.changeShipping = async function (formCheckout) {
  const endpoint = "cart/shipping";
  const formData = new FormData(formCheckout);

  formData.delete("woocommerce-process-checkout-nonce");
  formData.delete("coupon_code");

  for (const [key, value] of formData.entries()) {
    if (key.startsWith("cart")) {
      formData.delete(key);
    }
    if (
      (key === "on_warehouse_company" ||
        key === "on_warehouse_city" ||
        key === "on_warehouse_warehouse" ||
        key === "delivery_company" ||
        key === "delivery_city") &&
      value
    ) {
      formData.set(
        key,
        [
          value,
          document.querySelector(`option[value="${value}"]`).innerHTML.trim(),
        ].join("::")
      );
    }
  }

  // for (const [key, value] of formData.entries()) {
  //   // if key start from "cart" delete this key
  //   console.log(key, value);
  // }

  await sendFormDataOnTermoApi(endpoint, {
    formData: formData,
    loadingElement: formCheckout,
    success: (data) => {
      triggerShippingUpdatedEvent(data);
    },
  });
};

// window.changeShipping(document.getElementById("checkout"));

window.buyOneClick = async function (element) {
  MicroModal.show("modal-form-buy-one-click", {
    disableScroll: true,
    onShow: (modal) => {
      const inputProductID = document.getElementById(
        "modal-form-buy-one-click-lot-product_id"
      );
      const productName = element.closest("[data-product-title]").dataset
        .productTitle;
      const titleElement = document.getElementById(
        "modal-form-buy-one-click-title"
      );
      const titleDefault = titleElement.dataset.defaultTitle;
      const titleValue = productName
        ? titleDefault.replace(
          "%s",
          `<span class="border-b-2 border-red-theme">${productName}</span>`
        )
        : titleDefault.replace("%s", "");

      titleElement.innerHTML = titleValue;
      inputProductID.value = getProductIdFromElement(element);
      onShowMicroModal(modal);
    },
    onClose: (modal) => {
      const inputProductID = document.getElementById(
        "modal-form-buy-one-click-lot-product_id"
      );
      const titleElement = document.getElementById(
        "modal-form-buy-one-click-title"
      );
      inputProductID.value = "";
      const titleDefault = titleElement.dataset.defaultTitle;
      titleElement.innerHTML = titleDefault;
      onCloseMicroModal(modal);
    },
    awaitOpenAnimation: true,
    // awaitCloseAnimation: true,
  });
};

document.addEventListener("cartUpdated", (event) => {
  const data = event.detail;
  if (data.fragments) {
    refreshFragments(data.fragments);
  }
  document.querySelectorAll(".tippy-cart")?.forEach((el) => {
    el._tippy.setContent(
      data.count
        ? _n(
          "In cart is %s product",
          "In cart are %s products",
          data.count,
          "frontenda-store-front"
        ).format(data.count)
        : __("Cart is empty", "frontenda-store-front")
    );
  });
});

document.addEventListener("shippingUpdated", (event) => {
  const data = event.detail;

  if (data.notices.success.length) {
    showToastifyMesage(data.notices.success[0].notice);
  }

  if (data.fragments) {
    refreshFragments(data.fragments);
  }
});

function triggerCartUpdatedEvent(data) {
  const event = new CustomEvent("cartUpdated", { detail: data });
  document.dispatchEvent(event);
}
function triggerShippingUpdatedEvent(data) {
  const event = new CustomEvent("shippingUpdated", { detail: data });
  document.dispatchEvent(event);
}
function getProductIdFromElement(element) {
  const parent = element.closest("[data-id]");
  const productID = parent.dataset.id;

  return element.dataset.id ?? productID;
}
function serializeForm(form) {
  let obj = {};
  const formData = new FormData(form);
  for (let key of formData.keys()) {
    obj[key] = formData.get(key);
  }
  return obj;
}
function scrollHeaderPlugin() {
  const header = document.getElementById("header");
  const footer = document.getElementById("footer-mobile");
  const headerHeight = header.offsetHeight; // Отримуємо висоту хедера
  const windowHeight = window.innerHeight;
  const documentHeight = document.body.offsetHeight;
  let prevScrollPos = window.pageYOffset;
  let rafPending = false; // Флаг, що вказує, чи заплановано виклик requestAnimationFrame

  function handleScroll() {
    const currentScrollPos = window.pageYOffset;
    const downPoint = +windowHeight + +currentScrollPos;
    // Перевіряємо, чи проскролено більше ніж висота хедера
    if (currentScrollPos <= headerHeight) {
      rafPending = false;
      return;
    }

    if (prevScrollPos > currentScrollPos || documentHeight == downPoint) {
      // Коли скролимо вверх
      header.classList.remove("active");
      footer?.classList.remove("active");

      const eventScrollUp = new CustomEvent("scrollUp");
      window.dispatchEvent(eventScrollUp);
    } else if (prevScrollPos < currentScrollPos) {
      // Коли скролимо вниз
      header.classList.add("active");
      footer?.classList.add("active");

      const eventScrollDown = new CustomEvent("scrollDown");
      window.dispatchEvent(eventScrollDown);
    }

    prevScrollPos = currentScrollPos;
    rafPending = false; // Обнуляємо флаг після завершення виклику
  }

  function onScroll() {
    if (!rafPending) {
      rafPending = true;
      requestAnimationFrame(handleScroll);
    }
  }

  // Підписуємось на подію скролу
  window.addEventListener("scroll", onScroll);

  // window.addEventListener("scrollUp", () => {
  // 	sidebar.firstElementChild.firstElementChild.style.transform = "translateY(var(--header-height))";
  // })

  // window.addEventListener("scrollDown", () => {
  // 	sidebar.firstElementChild.firstElementChild.style.transform = "translateY(0px)";
  // })

  // sidebar.firstElementChild.firstElementChild.style.transition = "transform 0.3s ease-in-out";
  document.querySelectorAll('[data-index]')?.forEach(el => {
    const [x, y] = el.dataset.index.split(":");
    const parent = el.closest('.price-item');
    const elementX = parent.querySelector(`[data-x="${x}"]`);
    const elementY = parent.querySelector(`[data-y="${y}"]`);

    el.addEventListener('mouseenter', () => {
      elementX.classList.add('active');
      elementY.classList.add('active');
    })
    el.addEventListener('mouseleave', () => {
      elementX.classList.remove('active');
      elementY.classList.remove('active');
    })

    // Мені потрібен функціонал що рахує кількість кліків і після затримки в 2с відправляє запит на сервер
    let clickCount = 0;
    let clickTimeout;

    el.addEventListener('click', () => {
      clickCount++;
      el.classList.add('active');
      const overlay = document.createElement('div');
      overlay.classList.add("absolute", "inset-0", "bg-red-core/30");
      el.prepend(overlay)
      el.querySelector('span').dataset.count = clickCount;

      clearTimeout(clickTimeout);
      clickTimeout = setTimeout(() => {
        el.querySelectorAll('div.absolute').forEach(el => el.remove());

        el.classList.remove('active');

        el.dataset.qty = clickCount;
        window.addToCart(el, 'add')
        clickCount = 0
        el.dataset.qty = 1;
      }, 1000);
    })
  })
}
