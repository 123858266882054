class BurgerPlugin {
	constructor(element, target, type, options) {
		this.options = options || {};
		this.burgerEls = element;
		this.targetEl = target;
		this.type = type;
		this.overlayEl = document.getElementById("overlay");
		this.htmlEl = document.getElementById("html");
		// Додавання обробників подій
		this.burgerEls.forEach((element) => {
			element.addEventListener("click", this.toggleSidebar.bind(this));
		});
		this.overlayEl.addEventListener("click", this.close.bind(this));

		document.addEventListener("keyup", this.onKeyUp.bind(this));
	}

	closeAnySidebar() {
		this.htmlEl.classList.remove("open-sidebar");
		this.htmlEl.dataset.type = "";
	}

	open() {
		this.htmlEl.classList.add("open-sidebar");
		this.htmlEl.dataset.type = this.type;
		this.triggerEvent("burger-opened");
		this.burgerEls.forEach((element) => {
			element.setAttribute("aria-expanded", "true");
		});
		this.targetEl.removeAttribute("aria-hidden");
	}

	close() {
		this.htmlEl.classList.remove("open-sidebar");
		this.htmlEl.dataset.type = "";
		this.triggerEvent("burger-closed");
		this.burgerEls.forEach((element) => {
			element.setAttribute("aria-expanded", "false");
		});
		this.targetEl.setAttribute("aria-hidden", "true");
	}

	toggleSidebar(event) {
		event.preventDefault();
		if (this.isOpen()) {
			this.close();
		} else {
			this.closeAnySidebar();
			this.open();
		}
	}

	isOpen() {
		return (
			this.htmlEl.classList.contains("open-sidebar") &&
			this.htmlEl.dataset.type == this.type
		);
	}

	onKeyUp(event) {
		if (event.keyCode === 27 && this.isOpen()) {
			this.toggleSidebar(event);
		}
	}

	triggerEvent(eventName) {
		const event = new Event(eventName);
		document.dispatchEvent(event);
	}
}
export default BurgerPlugin;
